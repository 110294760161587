<template>
<div>
  <div class="container-fluid">
    <PageTitle />
  
    <div 
      v-if="isList" 
      class="card"
    >
      <div class="card-header">
        <h5 class="card-title">{{pageTitle}} List {{row.al_title}}</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-8">
            
          </div>
          <div class="col-md-4">
            <form @submit.prevent="doFilter(),doSearch()" v-if="total_data>0">
              <div class="form-group mb-0">
                <div class="input-group">
                  <input v-model="filter.search" type="text" class="form-control"
                    placeholder="Type and Press Enter" required="" @input="checkDisabled" />
                  <div class="input-group-append">
                    <button disabled id="btn-search" class="btn btn-info" type="submit"><i class="fas fa-search"></i></button>
                  </div>
                  <button v-if="isSearch" @click="doReset(),clsSearch()" type="button" class="btn btn-danger ml-1"><i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row gutter-20 mt-4">
          <div class="col-lg-4 col-md-6" v-for="(v,k) in data.data" :key="k">
            <div class="card h-100">
              <div class="card-body border_card" v-tooltip="v.at_update_date ? 'Last updated : ' + v.at_update_date.dates('format2') : 'Created : ' + v.at_create_date.dates('format2')">
                <div class="bullet-cta">
                  <router-link :to="{name:$route.name, params:{pageSlug:v[idKey]}}" role="button"
                    class="bullet_edit finger mr-2" v-tooltip="'Edit'"><i class="fas fa-pencil-alt"></i></router-link>
                  <a v-tooltip="'Change Status'" @click.prevent="doChangeStatus(k,v)" class="bullet_edit finger mr-2"><i
                      class="fa fa-cog"></i></a>
                  <a v-tooltip="'Delete'" @click.prevent="doDelete(v.as_id, v)" class="bullet_delete finger"><i
                      class="far fa-trash-alt"></i></a>
                </div>
                <div class="product-text d-flex flex-column align-items-start h-100 justify-content-between">
                  <div>
                    <div>
                      <h4 class="f-600"><strong>{{ v.at_name }}</strong> </h4>
                      <p class="mb-2 text-purple">{{ v.at_position }}</p>
                    </div>
                    <p class="mb-2">
                      {{ (v.at_testimoni || '').limitChar(180) }}
                    </p>
                  </div>
                  <div class="clearfix"></div>
                  <p class="mt-3 mb-0">
                    <span 
                      class="label"
                      :class="v.at_is_active === 'Y' ?'label-success' : 'label-danger'"
                    >{{ v.at_is_active === "Y" ? "Active" : "Inactive" }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

           <div class="col-lg-12 text-center" v-if="data.data.length === 0">
            <h4>
              {{ isSearch ? 'Testimonial Data Not Found' : 'Testimonial Data Not Available' }}
            </h4>
          </div>
        </div>
      </div>
      <div class="card-footer" v-if="data.last_page > 1">
        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <div class="pagination-flat float-right">
              <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                <span slot="next-nav"><i class="icon-arrow-right"></i></span>
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Add':'Edit'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-7">
                    <div class="form-group">
                      <label class="control-label">
                        Company Name 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="row.at_name"
                        type="text" 
                        class="form-control" 
                        placeholder="Company Name" 
                      /> 
                      <VValidate name="Name" v-model="row.at_name" :rules="mrValidation.at_name" />
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label class="control-label">
                        Position
                      </label>
                      <input 
                        v-model="row.at_position"
                        type="text" 
                        class="form-control" 
                        placeholder="Position" 
                      /> 
                      <VValidate name="Position" v-model="row.at_position" rules="min:5" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">
                        Testimonial Content
                        <span class="text-danger mr-5">*</span>
                      </label>

                      <textarea 
                        class="form-control"
                        rows="7" placeholder="Enter text..."
                        v-model="row.at_testimoni"
                      >
                      </textarea>

                      <VValidate name="Testimoni" v-model="row.at_testimoni" rules="required|min:20" />
                    </div>
                  </div>

                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">
                      Status 
                      <span class="text-danger mr5">*</span> 
                    </label>
                    <div>
                      <InputRadio v-model="row[statusKey]" name="status" :option="'Y'" :label="'Active'" @load="initRadio" ref="radio" />
                      <InputRadio v-model="row[statusKey]" name="status" option="N" />
                    </div>
                    <VValidate name="Status" v-model="row[statusKey]" rules="required" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                  <button type="submit" class="btn btn-block btn-rounded btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>
<style scoped>
button:disabled {
  cursor: auto;
}
</style>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'

let $ = global.jQuery

export default{
  extends:GlobalVue,
  components:{PageTitle},
  data(){
    return {
      idKey:'at_id',
      statusKey:'at_is_active',
      isSearch: false,
      total_data: '',
    }
  },
  mounted(){
    this.apiGet()

    this.row['at_create_by'] = this.user.id
    this.row['at_update_by'] = this.user.id 
    setTimeout(() => {
      if(this.isList){
        if(this.$route.query.search){        
          if (this.filter && this.filter.search.length <= 0) {
            this.isSearch = false
            $('#btn-search').attr("disabled", "disabled")
          } else {
            this.isSearch = true
            $('#btn-search').removeAttr("disabled")
          }  
        }
      }

      this.initRadio()
      this.row["at_is_active"] = "Y"

    }, 300)


  },
  methods: {
    doSearch (){
        this.isSearch = true
    },
    clsSearch(){
      this.isSearch = false
      setTimeout(() => {
        $('#btn-search').attr("disabled", "disabled")
      }, 0)
    },
    doReset(){
      this.filter={}
      this.$router.push({name:this.$route.name,query:{page:1}});
      setTimeout(() => {
        $('#btn-search').attr("disabled", "disabled")
      }, 0)
      
    },
    initRadio(){
      if(this.$refs.radio){
        this.$refs.radio.value = "Y"
      }
    },
    checkDisabled() {
      if (this.filter.search.length <= 0) {
        $('#btn-search').attr("disabled", "disabled")
      } else {
        $('#btn-search').removeAttr("disabled")
      }
    },
  },
  watch:{
    $route(){
      this.apiGet()

      setTimeout(() => {
        if (!this.isList) {
          this.isSearch = false
          this.filter = {
            category: 0,
            status: 0
          }
        } else {
          this.filter = {
            category: 0,
            status: 0,
            ...this.filter
          }
          // $('#btn-search').removeAttr("disabled")
        }
        this.row["at_is_active"] = "Y"
        this.initRadio()
      }, 300)

    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    },
  }
}
</script>